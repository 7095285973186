import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar } from '@mui/material';

// project imports
import Header from './Header';
import ECAPAppBar from './AppBar/ResponsiveAppBar';
import Footer from './Footer'
import ContactUs from 'views/pages/contactus/ContactUs';
import LatestSplash from 'ui-component/splash/latest-splash';
import useAppSettings from 'hooks/useAppSettings';

// assets

// styles
const Main = styled('main')(({ theme, appSettings }) => ({
    ...theme.typography.mainContent,    
    ...({
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        width: '100%',
        marginRight: 0,
        marginTop: (appSettings.HIDEAPPHEADER && appSettings.HIDEAPPHEADER) ? '0px' : '135px',        
        [theme.breakpoints.down('md')]: {
            marginLeft: '20px'
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0px',
            marginTop: '128px'
        }
    })
}));

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const theme = useTheme();
    const { appSettings } = useAppSettings();


    return (
        <>
        <Box sx={{ display: 'flex' }}>
            
            <CssBaseline />
            {/* header */}
            {(!appSettings.HIDENAVBAR || !appSettings.HIDEAPPHEADER) && <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    bgcolor: theme.palette.background.default
                }}
            >
                {!appSettings.HIDEAPPHEADER && <Toolbar>
                    <Header />
                </Toolbar>}
                {!appSettings.HIDENAVBAR && <Toolbar variant="dense" disableGutters>
                    <ECAPAppBar />
                </Toolbar>}
            </AppBar>}

            {/* main content */}
            <Main theme={theme} appSettings={appSettings} sx={{ bgcolor: '#E5E5E5' }}>
               
                <Outlet />
                <ContactUs />
                <LatestSplash />
            </Main>           
            </Box>
            <Footer />
            </>
    );
};

export default MainLayout;
